import React, { useRef } from "react"

import { Layout } from "@components/layout"
import { Gallery } from "@components/standard"

const GalleryPage = ({ pageContext, location }) => {
  const { page } = pageContext
  return (
    <Layout {...page}>
      <Gallery />
    </Layout>
  )
}

export default GalleryPage
